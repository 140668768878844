






































































import customerService from '@/services/customer.service';
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import SelectInput from '@/components/shared/forms/SelectInput.vue';
import SubmitButton from '@/components/shared/SubmitButton.vue';
import moment from 'moment';
import find from 'lodash/find';
import RadioInput from '@/components/shared/forms/RadioInput.vue';
import ConfirmModal, {
  IConfirmModal
} from '@/components/shared/ConfirmModal.vue';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useCustomersStore } from '@/store/customers-store';
import { Customer } from '@/model/customer';
import { AlertType, useUiStore } from '@/store/ui-store';

export default Vue.extend({
  components: {
    LoadingIndicator,
    SelectInput,
    RadioInput,
    SubmitButton,
    ConfirmModal
  },
  data() {
    return {
      initializing: true,
      customersToMerge: [],
      customer: {
        email: '',
        lastname: '',
        firstname: '',
        address: '',
        addressComplement: '',
        zip: '',
        city: '',
        country: '',
        birthdate: '',
        mobilePhone: '',
        mobileProPhone: '',
        workPhone: '',
        homePhone: ''
      },
      confirmMergeModal: {} as IConfirmModal
    };
  },
  computed: {
    ...mapStores(useUiStore, useCustomersStore),
    customers(): Customer[] {
      return this.customersStore.entities;
    },
    atLeastOneUserAccountExists(): boolean {
      return this.customersToMerge.some((customer: any) => {
        return customer.user_id !== null;
      });
    },
    allCustomerFieldsMatchExceptEmail(): boolean {
      for (const fieldName in this.customer) {
        if (
          fieldName !== 'email' &&
          this.getSelectOptionsForField(fieldName).length > 1
        ) {
          return false;
        }
      }
      return true;
    }
  },
  async mounted() {
    this.confirmMergeModal = (this.$refs
      .confirmMergeModal as unknown) as IConfirmModal;
    this.initializing = true;
    if (this.$route.query.ids.length < 2) {
      await this.$router.push({ name: 'CustomersView' });
    } else {
      const customersIds = this.$route.query.ids as [];
      const customersToFetchPromises = [] as any;
      customersIds.forEach(customerId => {
        const customerFromState = this.customers.find((customer: any) => {
          return customer.id === customerId;
        });
        if (customerFromState) {
          this.customersToMerge = (this.customersToMerge as any).concat([
            customerFromState
          ]);
        } else {
          customersToFetchPromises.push(customerService.getById(customerId));
        }
      });
      try {
        this.customersToMerge = this.customersToMerge.concat(
          await Promise.all(customersToFetchPromises)
        );
      } catch (exception) {
        this.uiStore.alert(exception.message);
        await this.$router.push({ name: 'CustomersView' });
      }
    }
    // Par défaut, les valeurs du premier sélectionné si une valeur existe
    for (const fieldName in this.customer) {
      if (this.getSelectOptionsForField(fieldName).length >= 1) {
        this.customer[fieldName] = this.getSelectOptionsForField(
          fieldName
        )[0].label;
      }
    }
    this.initializing = false;
  },
  methods: {
    getSelectOptionsForField(fieldName: string) {
      const options = [] as any;
      this.customersToMerge.forEach((customer: Customer) => {
        if (fieldName === 'email' && this.atLeastOneUserAccountExists) {
          /**
           * S'il y a un ou plusieurs comptes utilisateurs dans les employés, on propose UNIQUEMENT les comptes utilisateurs pour l'e-mail
           * S'il y a aucune compte utilisateur, on laisse libre
           */
          if (customer.user_id) {
            // On propose UNIQUEMENT le(s) compte(s) utilisateur(s)
            options.push({
              value: customer.email,
              label: customer.email
            });
          }
        } else {
          if (customer[fieldName]) {
            // On n'ajoute pas les valeurs vides
            let value = customer[fieldName];
            let label = customer[fieldName];
            if (fieldName === 'birthdate') {
              value = customer[fieldName];
              label = moment(customer[fieldName]).format('DD.MM.YYYY');
            } else {
              value = customer[fieldName];
              label = customer[fieldName];
            }
            if (!find(options, { value: value })) {
              // On n'ajoute pas de valeurs déjà présentes dans les options
              options.push({
                label,
                value
              });
            }
          }
        }
      });
      return options;
    },
    changeEmail(email) {
      this.customer.email = email;
    },
    onSubmit() {
      this.confirmMergeModal.openModal(
        'Cette action est définitive et irréversible. Merci de confirmer la fusion en cliquant sur le bouton "Fusionner".',
        {
          okTitle: 'Fusionner',
          okVariant: 'primary'
        }
      );
    },
    async mergeCustomers() {
      const customerIdToKeep = (this.customersToMerge as any).find(
        (customer: any) => {
          return customer.email === this.customer.email;
        }
      ).id;
      const customersIdsToDelete = this.customersToMerge
        .filter((customer: any) => {
          return customer.id !== customerIdToKeep;
        })
        .map((customer: any) => {
          return customer.id;
        });
      try {
        const response = await customerService.merge(
          customerIdToKeep,
          customersIdsToDelete,
          this.customer
        );
        const successMessage = response.message;
        const customer = response.customer;
        this.confirmMergeModal.sendingData = false;
        this.confirmMergeModal.closeModal();
        this.uiStore.alert(successMessage, AlertType.SUCCESS);
        this.customersStore.currentEntity = customer;

        await this.$router.push({
          name: 'CustomersView',
          params: { id: customerIdToKeep }
        });
      } catch (exception) {
        this.confirmMergeModal.sendingData = false;
        this.uiStore.alert(exception.message);
      }
    }
  }
});
