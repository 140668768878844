var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-content"},[_vm._m(0),_c('div',{staticClass:"dashboard-card"},[(_vm.initializing)?_c('loading-indicator'):_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_c('radio-input',{attrs:{"default-value":_vm.customer.email,"options":_vm.getSelectOptionsForField('email'),"name":"email","label":_vm.$t('label.email'),"help-text":_vm.atLeastOneUserAccountExists
            ? 'Le mot de passe et nom d\'utilisateur associés à ce compte seront gardés.'
            : null},on:{"onValueChange":function($event){return _vm.changeEmail($event)}}}),_c('hr'),(_vm.allCustomerFieldsMatchExceptEmail)?_c('div',{staticClass:"alert alert-success"},[_c('i',{staticClass:"fas fa-check-circle mr-2"}),_vm._v(" Aucun autre conflit à signaler. ")]):_c('div',[_c('div',{staticClass:"alert alert-info"},[_c('div',[_c('i',{staticClass:"fas fa-info-circle mr-2"}),_vm._v(" Nous avons constaté que certains champs sont en conflit, merci de sélectionner la valeur correcte. Vous pourrez toujours éditer le compte client après la fusion si aucune valeur ne correspond. ")])]),_vm._l((_vm.customer),function(value,fieldName){return _c('div',{key:fieldName},[(
              fieldName !== 'email' &&
                _vm.getSelectOptionsForField(fieldName).length > 1
            )?_c('select-input',{attrs:{"value":_vm.customer[fieldName],"options":_vm.getSelectOptionsForField(fieldName),"name":fieldName,"label":_vm.$t('label.' + fieldName),"allow-empty":false},on:{"onValueChange":function (value) {
                _vm.customer[fieldName] = value;
              }}}):_vm._e()],1)})],2),_c('div',{staticClass:"pt-1"},[_c('submit-button',{attrs:{"label":"Confirmer la fusion"}}),_c('button',{staticClass:"btn btn-secondary ml-3",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" "+_vm._s(_vm.$t('button.cancel'))+" ")])],1)],1),_c('confirm-modal',{ref:"confirmMergeModal",on:{"confirm":_vm.mergeCustomers}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-header"},[_c('div',{staticClass:"dashboard-header-title"},[_vm._v("Fusionner les clients")])])}]

export { render, staticRenderFns }